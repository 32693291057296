<template>
  <div>
    <h3>Create Residential Address Audience</h3>
    <div class="sections">
      <div>
        <reverse-appends-form @upload="handleUpload" />
      </div>
    </div>
  </div>
</template>

<script>
import ReverseAppendsForm from '../../audience/ReverseAppendsForm';

export default {
  components: {
    ReverseAppendsForm,
  },

  methods: {
    handleUpload() {
      this.$refs.requestTable.reload();
    },
  },
};
</script>

<style lang="scss" scoped>
.sections > div:not(:last-of-type) {
  margin-bottom: 1em;
}
</style>
