<template>
  <el-dialog
    :before-close="handleClose"
    :visible="visible"
    :title="`The following issue${problems.length === 1 ? ' was' : 's were'} detected`"
    width="80%"
  >
    <el-table
      :data="problems"
    >
      <el-table-column
        v-for="(header, idx) in headers"
        :key="idx"
        :label="header.charAt(0).toUpperCase() + header.slice(1)"
        :prop="header"
      >
        <template slot-scope="{ row }">
          <div :class="{ error: row.field === header }">
            {{ row[header] }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="Problem">
        <template slot-scope="{ row }">
          Invalid {{ row.field }}
        </template>
      </el-table-column>
    </el-table>
  </el-dialog>
</template>

<script>
export default {
  name: 'CsvProblems',
  props: {
    headers: { type: Array, default: () => [] },
    problems: { type: Array, default: () => [] },
    visible: { type: Boolean, default: false },
  },

  methods: {
    handleClose() {
      this.$emit('close');
    },
  },
};
</script>

<style scoped>
  .error {
    color: #f66;
  }
</style>
